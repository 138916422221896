<template>
  <div class="d-flex">
    <checkbox-filter
      v-model="filters.isActivated"
      name="Actief"
      :options="new Map([
        [true, 'Ja'],
        [false, 'Nee'],
      ])"
    />

    <material-input
      id="app-users-search"
      v-model="searchQuery"
      class="ms-2"
      placeholder="Zoeken..."
      type="search"
    />
  </div>
</template>

<script>
import CheckboxFilter from '@/components/UI/CheckboxFilter.vue';
import MaterialInput from '@/components/MaterialInput.vue';

export default {
  components: {
    CheckboxFilter,
    MaterialInput
  },

  props: {
    showFilters: {
      type: Boolean,
      default: false
    },
    members: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: Array,
      default: undefined
    }
  },

  emits: [
    'update:modelValue',
  ],

  data() {
    return {
      filters: {
        isActivated: [],
      },
      searchText: ''
    }
  },

  computed: {
    result() {
      return this.getResults()
    },

    searchQuery: {
      get() { return this.searchText; },
      set(value) {
        this.searchText = value;
        this.$emit('update:modelValue', this.getResults());
      }
    }
  },

  updated() {
    this.$emit('update:modelValue', this.getResults());
  },

  methods: {
    getResults() {
      return this.members
        .filter(m => 
          this.filters.isActivated.length === 0 ||
          this.filters.isActivated.includes(m.isActivated)
        )
        .filter(
        m => m.fullName.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    }
  }
}
</script>

<style scoped>
form {
  z-index: 1;
}
</style>