<template>
  <div class="form-check p-0">
    <input
      :id="id"
      v-model="computedValue"
      class="form-check-input"
      :name="name"
      type="checkbox"
      :value="value"
    />
    <label :for="id" class="custom-control-label">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: "MaterialCheckbox",

  props: {
    id: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: "",
    },
    value: {
      type: [
        Boolean,
        String,
      ],
      required: true,
    },
  },

  emits: [
    'update:modelValue',
  ],

  computed: {
    computedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>
