<template>
  <div class="card">
    <div
      v-if="$slots.header != undefined"
      class="card-header pb-0"
    >
      <slot name="header" />
    </div>

    <div class="card-body">
      <slot />
    </div>

    <div
      v-if="$slots.footer != undefined"
      class="card-footer pt-0"
    >
      <slot name="footer" />
    </div>
  </div>
</template>

<script>

</script>