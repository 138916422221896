<template>
  <div class="user-card card">
    <div class="p-3 card-body">
      <div class="d-flex mt-n2">
        <div
          class="avatar avatar-xl bg-gradient-dark border-radius-xl mt-n4 overflow-hidden
                 flex-shrink-0"
        >
          <router-link
            :to="routerLink"
            class="user-card__link"
          >
            <profile-picture
              :width-and-height-in-pixels="75"
              :background-image-url="member.profilePicture"
            />

          </router-link>
        </div>
        
        <div class="d-flex flex-column w-100 ms-2">
          <div class="d-flex align-items-center justify-content-between w-100">
            <router-link
              :to="routerLink"
              class="user-card__link"
            >
              <h6 class="m-0">{{ member.fullName }}</h6>
            </router-link>

            <material-icon
              icon="link_off"
              @click.prevent="remove"
            />
          </div>
          <div
            class="d-flex gap-2 mt-2"
          >
            <material-badge
              v-if="member.isAdmin"
              size="sm"
              color="warning"
            >
              Admin
            </material-badge>

            <material-badge
              v-if="member.isActivated"
              size="sm"
              color="success"
            >
              Actief
            </material-badge>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import MaterialBadge from '@/components/MaterialBadge.vue';
import MaterialIcon from '@/components/UI/MaterialIcon.vue';
import ProfilePicture from '@/components/UI/ProfilePicture.vue';

import { createYesNoSweetAlert } from '@/helpers/sweetalert';

export default {
  components: {
    MaterialBadge,
    MaterialIcon,
    ProfilePicture
  },

  props: {
    member: {
      type: Object,
      required: true
    }
  },

  emits: [
    'removed',
  ],

  computed: {
    routerLink() {
      return {
        name: 'edit-app-user',
        params: {
          id: this.member.id
        },
      }
    }
  },

  methods: {
    ...mapActions('notification', {
      addNotification: 'add',
    }),

    edit() {
      this.$router.push(this.routerLink);
    },

    remove() {
      createYesNoSweetAlert(
        `Weet u zeker dat u ${this.member.fullName} wilt ontkoppelen van de groep?`,
        () => {
          this.$emit('removed', this.member.id);
        }
      )
    }
  }
};
</script>

<style scoped lang="scss">
.user-card {
  height: 100%;

  &__link {
    display: grid;
    place-content: center;
  }

  &__image {
    object-fit: cover;
  }

  .material-icons-round:hover {
    cursor: pointer;
    color: #ec407a;
  }
}
</style>
